body {
  font-family: Lato, sans-serif;
  font-weight: 300;
  font-size: 16px;
  background-color: #163037;
  color: #bcc8cc;
}

h2, h3, h4 {
  color: #fff;
  font-weight: 300;
}

h1 {
  text-align: center;
  font-weight: 400;
  font-size: 62px;
  color: #fff;
  margin-top: 2em;
  margin-bottom: 0;
  line-height: 1em;
}

h2 {
  font-size: 44px;
  text-align: center;
  margin: 0 0 22px;
  padding-bottom: 16px;
  &:after {
    content: '';
    display: block;
    margin: 0 auto;
    width: 180px;
    border-bottom: 1px solid;
    border-color: #bcc8cc;
    position: relative;
    top: 16px;
  }
}

h3 {
  font-size: 24px;
  margin-bottom: 12px;
}

h4 {
  font-size: 20px;
  margin: 10px 0;
}

section {
  padding: 80px 0;
  background-color: #40565c;
  overflow: hidden;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
}

footer {
  padding: 80px 0;
  background-color: #40565c;
  overflow: hidden;
}

p {
  margin-top: 0;
}

.icon {
  font-size: 64px;
}

.subtitle {
  font-family: Merriweather, serif;
  font-size: 17px;
  font-weight: 300;
  font-style: italic;
  text-align: center;
  max-width: 320px;
  margin: 0 auto;
  margin-bottom: 60px;
}

a {
  color: #8a9599;
  &:hover {
    color: #576266;
    cursor: pointer;
  }
  &:active, &:focus, &:hover {
    outline: 0;
    text-decoration: none;
  }
}

.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.alert-success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

/* FORMULARZE */

input:focus, button:focus, textarea:focus {
  outline: 0;
  text-decoration: none;
}

input, textarea {
  width: 100%;
  font-size: 18px;
  min-height: 60px;
  border-radius: 4px;
  line-height: 24px;
  padding: 16px 30px 20px;
  border: none;
  margin-bottom: 10px;
  background-color: #e9f0f2;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
  &:focus {
    background-color: #e4edf0;
  }
}

/**********************
 ******1. Buttons****
 **********************/

.btn {
  color: #fff;
  font-size: 18px;
  min-height: 60px;
  border-radius: 4px;
  text-decoration: none;
  display: inline-block;
  line-height: 24px;
  padding: 16px 30px 20px;
  z-index: 1;
  position: relative;
  border: none;
}

.btn-icon {
  line-height: 32px;
  padding: 16px 32px 20px;
  .icon {
    vertical-align: bottom;
    font-size: 32px;
    margin-right: 10px;
    display: inline-block;
  }
}

.btn-primary {
  background-color: #f09f4a;
}

.btn-secondary {
  background-color: #09b8ed;
}

.btn {
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 1px;
    bottom: 1px;
    left: 1px;
    right: 1px;
    z-index: -1;
    border-radius: 40px;
    -webkit-transition-property: top, bottom, left, right;
    -webkit-transition-duration: 0.21s;
    -webkit-transition-timing-function: ease-in-out;
    transition-property: top, bottom, left, right;
    transition-duration: 0.21s;
    transition-timing-function: ease-in-out;
  }
  &:hover {
    text-decoration: none;
    color: #fff;
    &:before {
      top: -3px;
      bottom: -3px;
      left: -3px;
      right: -3px;
    }
  }
}

.btn-primary:before {
  background-color: #f09f4a;
}

.btn-secondary:before {
  background-color: #09b8ed;
}

/**********************
 ******2. Menu Bar****
 **********************/

.nav {
  float: right;
  padding: 0;
  ul {
    margin: 0;
  }
  li {
    float: left;
    list-style-type: none;
    margin-left: 40px;
    text-transform: uppercase;
    position: relative;
    a {
      color: #fff;
      font-size: 14px;
    }
    &:after {
      opacity: 0;
      bottom: -8px;
      content: '';
      display: block;
      width: 100%;
      position: absolute;
      bottom: -4px;
      transition: all 0.2s;
      border-bottom: 2px solid;
    }
    &.current:after {
      bottom: -8px;
      opacity: 1;
    }
    &:not(.current):hover:after {
      opacity: 0.6;
      bottom: -8px;
    }
  }
}

#menu-bar-fixed {
  display: block;
  width: 100%;
  height: 80px;
  padding: 15px 0;
  background-color: rgba(0,0,0,0.8);
  position: fixed;
  top: 0px;
  z-index: 5;
  .logo {
    font-size: 28px;
    line-height: 45px;
    float: left;
    color: white;
    img {
      height: 30px;
    }
  }
}

#nav-fixed {
  margin: 14px 0;
}

#show-nav, #hide-nav {
  display: none;
}

#off-nav {
  display: none;
}

/**********************
 ******3. Header****
 **********************/

header {
  background-color: #102129;
  background-size: cover;
  max-height: 900px;
  position: relative;
  overflow: visible;
  text-align: center;
  .container {
    position: relative;
    z-index: 3;
  }
}

div > a.btn:nth-of-type(2) {
  margin-left: 30px;
}

/**********************
 ******12. Footer****
 **********************/

footer {
  background-color: #163037;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -4;
  text-align: center;
  h2 {
    color: #fff;
    margin-bottom: 30px;
    img {
      height: 48px;
    }
  }
  .copyright {
    font-size: 14px;
  }
}

/**********************
 ******13. Owl Carousel****
 **********************/

/**********************
 ******14. Media Queries****
 **********************/
@media (max-width: 1199px) {
  .nav li {
    margin-left: 30px;
  }
}

@media (max-width: 991px) {
  #nav {
    margin: 38px 0 0;
  }
  section {
    padding: 60px 0;
  }
}

@media (max-width: 767px) {
  * {
    text-align: center;
  }
  section {
    border-top: 48px solid transparent;
    margin-top: -48px;
  }
  /*Responsive navigation*/
  #nav-fixed li {
    position: relative;
  }
  #show-nav {
    display: block;
  }
  #nav-fixed > ul {
    height: auto;
    display: none;
    position: absolute;
    width: 100%;
    left: 0;
    padding: 0;
    background-color: rgba(0, 0, 0, 0.8);
    border-top: 1px solid #2a434a;
    padding: 8px 0;
    z-index: 10;
    > li {
      width: 100%;
      float: none;
      margin: 0;
      &:after {
        content: none;
      }
      &.current {
        background-color: rgba(120, 120, 120, 0.7);
      }
      > a {
        font-size: 18px;
        padding: 8px 0;
        display: inline-block;
        width: 100%;
      }
    }
  }
  #nav {
    display: none;
  }
  #menu-bar-fixed {
    height: 50px;
    padding: 0;
    .logo {
      font-size: 22px;
      padding: 8px 0;
      line-height: inherit;
    }
  }
  #nav-fixed {
    margin: 0;
    > a {
      height: 50px;
      width: 50px;
      > div {
        background-color: #ddd;
        height: 4px;
        margin: 0 auto;
        position: relative;
        top: 23px;
        width: 20px;
        &:before, &:after {
          background-color: #ddd;
          height: 4px;
          margin: 0 auto;
          position: relative;
          top: 23px;
          width: 20px;
        }
        &:before {
          content: "";
          display: block;
          height: 4px;
          top: -8px;
        }
        &:after {
          content: "";
          display: block;
          top: 4px;
        }
      }
    }
  }
  #off-nav {
    display: block;
    position: absolute !important;
    z-index: -1;
    top: 266px;
    width: 100%;
    height: 260%;
  }
}

@media (max-width: 530px) {
  h1 {
    font-size: 42px;
    font-weight: 400;
  }
  h2 {
    font-size: 42px;
  }
  h4, .btn {
    font-weight: 400;
  }
  section {
    padding: 45px 0;
  }
}

@media (max-width: 399px) {
  #menu-bar-fixed .logo img {
    height: 24px;
  }
  h1 {
    font-size: 33px;
    margin-bottom: 28px;
  }
  h2 {
    font-size: 33px;
  }
}

@media (min-width: 668px) and (max-width: 767px) {
  h1 {
    padding: 0 10%;
  }
}

header {
  background: #102129 url('../img/bg-electric.jpg') no-repeat top center;
  background-size: cover;
}
